import React from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom' 
import { isAuthenticated } from './services/auth'
import { ImSpinner8 } from 'react-icons/im'

import './assets/css/global.css'

const Loading = (
	<div className='fallbackLoading'>
		<div className="row">
			<div className="col-12">
				<center><ImSpinner8 className='RotateIcon waitIcon'/></center>
			</div>

			<div className="col-12">
				<center>Carregando...</center>
			</div>
		</div>
	</div>
)


const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
			)
		}
	/>
)

const Register = React.lazy(() => import('./pages/register'))
const Login = React.lazy(() => import('./pages/login'))
const ForgotPass = React.lazy(() => import('./pages/forgotpassword'))
const ResetPass = React.lazy(() => import('./pages/resetpassword'))
const ActivationAccount = React.lazy(() => import('./pages/activationaccount'))
const KalungaValidation = React.lazy(() => import('./pages/kalungavalidation'))
const TheLayout = React.lazy(() => import('./containers/TheLayout'));


export default class App extends React.Component {

  	render(){
		return(
			<BrowserRouter>
				
				<React.Suspense fallback={Loading}>
					<Switch>
						<Route exact path="/login" render={props => <Login {...props} />} />
						<Route exact path="/cadastrar" render={props => <Register {...props} />} />
						<Route exact path="/solicitar-senha" render={props => <ForgotPass {...props} />} />
						<Route exact path="/recuperar-senha" render={props => <ResetPass {...props} />} />
						<Route exact path="/ativacao-conta" render={props => <ActivationAccount {...props} />} />
						<Route exact path="/kalunga" render={props => <KalungaValidation {...props} />} />
						
						
						<PrivateRoute path="/" component={props => <TheLayout {...props}/>} />

						<Route path="*" component={() => <h1>Page not found</h1>} />

					</Switch>
				</React.Suspense>
			</BrowserRouter>
		)
  	}
}