import 'react-app-polyfill/ie11'; // For IE 11 support
// import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'fast-text-encoding/text';


import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import store from './store'



ReactDOM.render(
    <React.StrictMode>
    <Provider store={store}>
      	<App/>
    </Provider>
    </React.StrictMode>, 
  	document.getElementById('root')
);