import api from './api'
import { apiEndpoint } from './../constants/global'

export const TOKEN_KEY = "";
export const USER_DATA = null;
export const USER_DISTRI = null;


export const isAuthenticated  = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getUserData = () => localStorage.getItem(USER_DATA);



export const login = (token) => {
    localStorage.setItem(TOKEN_KEY, token)
}

export const setUserData = (user_data) => {
    localStorage.setItem(USER_DATA, user_data)
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(USER_DATA)


    var url = apiEndpoint + 'auth/logout'
    api.get(url)
}

export const MYDISTRI = "";
export const inputDistri = (distri) => {
    localStorage.setItem(MYDISTRI, distri)
}
export const myDistri = () => {
    localStorage.getItem(MYDISTRI)
}
