
export const altProp = 'Epson Próxima Parada - Edição Ouro'

export const apiEndpoint = 'https://api.proximaparadaedicaoouro.bckpatl.com.br/'

export const loginImageHeader = require('./../assets/images/logo_header.svg')
export const loginSplashImage = require('./../assets/images/splash_login.svg')
export const logoEpson = require('./../assets/images/logo_epson.svg')
export const logoAppBar = require('./../assets/images/logo-appBar.png')
export const logoAppBarFixed = require('./../assets/images/logo-appBar-fixed.png')

export const userAppIcon = require('./../assets/images/user-icon.png')
export const iconDown = require('./../assets/images/icon-down.png')
export const iconUp = require('./../assets/images/icon-up.png')

export const iconCross = require('./../assets/images/cross.png')
export const iconMenu = require('./../assets/images/menu.png')

export const bgDistri1 = require('./../assets/images/bg-distri-1.png')
export const distri1slide1gold = require('./../assets/images/distri1-slide1-gold.png')
export const distri1slide2arrow = require('./../assets/images/distri1-slide2-arrow.png')
export const distri1slide3Bik = require('./../assets/images/distri1-slide3-bik.png')
export const distri1slide4gold = require('./../assets/images/distri1-slide4-gold.png')

export const logoFooter = require('./../assets/images/logo-footer.png')

export const bgPerformance = require('./../assets/images/bg-header-performance.png')
export const packprods = require('./../assets/images/pack-prods.png')

export const ditri2gold = require('./../assets/images/distri2-gold.png')
export const ditri2goldMobile = require('./../assets/images/card.jpg')

export const coins = require('./../assets/images/coins-parallax.png')
export const coins2 = require('./../assets/images/coins-b2.png')


export const distri3Slide1 = require('./../assets/images/distri3-slide1.png')
export const distri3Slide2 = require('./../assets/images/distri3-slide2.png')
export const distri3Slide3 = require('./../assets/images/distri3-slide3.png')

export const logoKalungaXEpson = require('./../assets/images/kalunga_x_epson.png')


export const cardsKalunga = require('./../assets/images/cards.png')
export const coinsKalunga = require('./../assets/images/coins-kalunga.png')
export const coinsKalunga2 = require('./../assets/images/coins-kalunga2.png')